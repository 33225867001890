export default [
  {
    header: 'Master Data',
    resource: 'master-data',
    action: 'view',
  },
  {
    title: 'Site',
    route: 'master-data-area',
    icon: 'MapIcon',
    resource: 'area',
    action: 'view',
  },
  {
    title: 'Checkpoint',
    route: 'master-data-checkpoint',
    icon: 'MapPinIcon',
    resource: 'checkpoint',
    action: 'view',
  }
]