export default [
  {
    header: 'Report',
    resource: 'report',
    action: 'view',
  },
  {
    title: 'Patrol Summary',
    route: 'patrol-summary',
    icon: 'FileTextIcon',
    resource: 'report',
    action: 'view',
  },
]
