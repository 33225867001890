export default [
  {
    header: 'Settings',
    resource: 'settings',
    action: 'view',
  },
  {
    title: 'Guards',
    route: 'settings-guards',
    icon: 'ShieldIcon',
    resource: 'guard',
    action: 'view',
  },
  {
    title: 'Users',
    route: 'settings-users',
    icon: 'UserIcon',
    resource: 'monitoring_user',
    action: 'view',
  },
  {
    title: 'Role',
    route: 'settings-roles',
    icon: 'UsersIcon',
    resource: 'monitoring_role',
    action: 'view',
  }
]
